import { IconButtonClassKey, ButtonClassKey, ButtonTypeMap } from '@material-ui/core';
import { OverrideProps } from '@material-ui/core/OverridableComponent';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { monochromeLightest, monochromeLight, black } from '../../abstracts/colours';

export const buttonTheme = (): Partial<Record<ButtonClassKey, CSSProperties>> => ({
  root: {
    borderRadius: '4px',
    fontSize: '16px',
    minWidth: '64px',
    boxShadow: 'none',
  },
  sizeLarge: {
    height: '44px',
    padding: '12px 16px',
    fontSize: '16px',
  },
  sizeSmall: {
    height: '36px',
    padding: '8px 16px',
    fontSize: '16px',
  },
  iconSizeSmall: {
    '& > *:first-child': {
      fontSize: 20,
    },
  },
  iconSizeLarge: {
    '& > *:first-child': {
      fontSize: 20,
    },
  },
  contained: {
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
    },
    '&:focus-visible': {
      boxShadow: 'none',
    },
    '&:disabled': {
      opacity: 0.3,
    },
  },

  outlined: {
    backgroundColor: 'transparent',
    '&:hover': {
      boxShadow: 'none',
    },
    '&:disabled': {
      opacity: 0.3,
    },
    '&:focus-visible': {
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
    },
  },
  text: {
    '&:hover': {
      backgroundColor: monochromeLightest,
    },
    '&:active': {
      backgroundColor: monochromeLight,
    },
    '&:disabled': {
      opacity: 0.3,
    },
  },
  textPrimary: {
    '&:active': {
      backgroundColor: monochromeLight,
    },
  },
});

export const buttonDefaultProps: Partial<OverrideProps<ButtonTypeMap<{}, 'button'>, 'button'>> = {
  variant: 'contained',
  color: 'primary',
  type: 'button',
  size: 'large',
  disableTouchRipple: true,
};

export const MuiIconButton: Partial<Record<IconButtonClassKey, CSSProperties>> = {
  root: {
    color: black,
    '&.hamburger': {
      marginLeft: -20,
      marginRight: 4,
    },
    '&:hover': {
      backgroundColor: monochromeLightest,
    },
    '&:active': {
      backgroundColor: monochromeLight,
    },
    '&:disabled': {
      color: 'rgba(0,0,0,0.3)',
    },
    '&.Mui-focusVisible': {
      border: '4px solid rgba(0, 116, 189, 0.3)',
      backgroundColor: 'transparent',
    },
  },
};

export default buttonTheme;
