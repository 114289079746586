import React from 'react';
import clsx from 'clsx';
import MuiButton, { ButtonProps as MuiButtonProps } from '@material-ui/core/Button';
import { capitalize } from '@material-ui/core/utils';

import { Color, InferPropTypes, NonNullFields } from '../types';
import { useStyles } from './styles';
import { oneOf } from 'prop-types';

export type ButtonColors = Color | 'destructive';

const buttonPropTypes = {
  color: oneOf(['primary', 'secondary', 'default', 'destructive']),
};

const buttonDefaultPropTypes = {
  color: 'primary',
};

export type ButtonProps = NonNullFields<InferPropTypes<typeof buttonPropTypes, typeof buttonDefaultPropTypes>> &
  Omit<MuiButtonProps, 'color'>;

const Button: React.FC<ButtonProps> = ({
  onClick,
  variant,
  color,
  disabled,
  size,
  type,
  startIcon,
  children,
  ...rest
}) => {
  const classes = useStyles();
  const className = classes?.[`${variant}${capitalize(color as ButtonColors)}`];
  const colorProp =
    ['default', 'inherit', 'primary', 'secondary'].indexOf(color as ButtonColors) > -1
      ? (color as 'default' | 'inherit' | 'primary' | 'secondary')
      : 'default';

  return (
    <MuiButton
      {...rest}
      onClick={onClick}
      variant={variant}
      color={colorProp}
      disabled={disabled}
      size={size}
      type={type}
      className={clsx(className, rest.className)}
      startIcon={startIcon}
    >
      {children}
    </MuiButton>
  );
};

Button.propTypes = buttonPropTypes;
Button.defaultProps = buttonDefaultPropTypes;

export default Button;
