import { AccordionClassKey, AccordionSummaryClassKey, Theme } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { black, monochromeLight, monochromeLightest } from '../../abstracts/colours';

export const accordionTheme: Partial<Record<AccordionClassKey, CSSProperties>> = {
  root: {
    boxSizing: 'border-box',
    marginTop: 0,
    '&$expanded': {
      margin: 0,
    },
    boxShadow: 'rgba(0, 0, 0, 0.04) 0px 4px 6px 0px',
    '&:before': {
      backgroundColor: 'transparent',
    },
    border: `1px solid ${monochromeLight}`,
    '&:last-child': {
      borderRadius: '0px 0px 6px 6px',
    },
    '&:nth-child(2)': {
      borderRadius: '6px 6px 0px 0px',
    },
    '&:not(:last-child)': {
      borderBottom: 'none',
    },
  },
};

export const accordionSummaryTheme = (theme: Theme): Partial<Record<AccordionSummaryClassKey, CSSProperties>> => ({
  root: {
    '&:hover': {
      backgroundColor: monochromeLightest,
    },
    '&:active': {
      backgroundColor: monochromeLight,
    },
    minHeight: 56,
  },
  expandIcon: {
    '& .MuiSvgIcon-root': {
      fill: black,
    },
    '&.MuiIconButton-root': {
      color: black,
    },
  },
  content: {
    display: 'block',
    margin: '18px 0',
    '&$expanded': {
      margin: '18px 0',
    },
    '& .MuiTypography-subtitle2': {
      color: theme.palette.text.primary,
    },
  },
});

export default {
  accordionTheme,
  accordionSummaryTheme,
};
