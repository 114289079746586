export const SMALL = '12px';
export const MEDIUM = '15px';
export const LARGE = '20px';
export const XLARGE = '24px';
export const XXLARGE = '35px';
export const sourceSansPro = 'Source Sans Pro';
export const lato = 'Lato';

export const fonts = {
  sourceSansPro,
  lato,
};

export default {
  SMALL,
  MEDIUM,
  LARGE,
  XLARGE,
  XXLARGE,
  sourceSansPro,
  lato,
  fonts,
};
