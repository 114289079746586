import { LinearProgressClassKey } from '@material-ui/core/';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { monochromeLight } from '../../abstracts/colours';
export const MuiLinearProgress: Partial<Record<LinearProgressClassKey, CSSProperties>> = {
  colorSecondary: {
    backgroundColor: monochromeLight,
  },
  colorPrimary: {
    backgroundColor: monochromeLight,
  },
};
