import {
  CardContentClassKey,
  CardActionAreaClassKey,
  CardClassKey,
  CardActionsClassKey,
  CardHeaderClassKey,
} from '@material-ui/core/';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { monochromeLightest, monochromeLight } from '../../abstracts/colours';
export const MuiCardContent: Partial<Record<CardContentClassKey, CSSProperties>> = {
  root: {
    padding: '24px 16px',
  },
};
export const MuiCardActionArea: Partial<Record<CardActionAreaClassKey, CSSProperties>> = {
  root: {
    '&:hover': {
      backgroundColor: monochromeLightest,
    },
    '&:active': {
      backgroundColor: monochromeLight,
    },
  },
};
export const MuiCard: Partial<Record<CardClassKey, CSSProperties>> = {
  root: {
    border: `1px solid ${monochromeLight}`,
    borderRadius: 6,
    boxShadow: '0 4px 6px 0 rgba(0, 0, 0, 0.04)',
    flexDirection: 'row',
  },
};
export const MuiCardActions: Partial<Record<CardActionsClassKey, CSSProperties>> = {
  root: {
    justifyContent: 'flex-end',
    borderTop: `1px solid ${monochromeLight}`,
    padding: 16,
  },
};

export const MuiCardHeader: Partial<Record<CardHeaderClassKey, CSSProperties>> = {
  root: {
    padding: 0,
  },
  avatar: {
    alignSelf: 'flex-start',
  },
};
