import {
  DividerClassKey,
  ListClassKey,
  ListItemClassKey,
  MenuClassKey,
  PaperClassKey,
  SelectClassKey,
} from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { monochromeDarkest, monochromeLight, monochromeLightest, monochromeMid } from '../../abstracts/colours';

const selectTheme: Partial<Record<SelectClassKey, CSSProperties>> = {
  icon: {
    color: monochromeDarkest,
    marginRight: 12,
  },
  root: {
    backgroundColor: 'transparent',
  },
  select: {
    display: 'flex',
    paddingRight: 20,
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
};

const paperTheme: Partial<Record<PaperClassKey, CSSProperties>> = {
  root: {
    marginTop: 8,
  },
};

const menuTheme: Partial<Record<MenuClassKey, CSSProperties>> = {
  paper: {
    boxShadow: '0px 4px 6px 0px rgba(0, 0, 0, 0.12)',
    border: `1px solid ${monochromeMid}`,
    maxHeight: '400px',
  },
};

const listTheme: Partial<Record<ListClassKey, CSSProperties>> = {
  padding: {
    paddingTop: 8,
    paddingBottom: 8,
  },
  root: {
    '& > .MuiMenuItem-root': {
      marginBottom: 0,
      height: 44,
      padding: '12px 36px 12px 16px',
    },
    '& > .phone-input-menu-item': {
      flexDirection: 'row-reverse',
      justifyContent: 'flex-end',
    },
  },
};

const dividerTheme: Partial<Record<DividerClassKey, CSSProperties>> = {
  root: {
    marginTop: 12,
    marginBottom: 12,
  },
};

const listItemTheme: Partial<Record<ListItemClassKey, CSSProperties>> = {
  button: {
    '&:hover': {
      backgroundColor: monochromeLightest,
    },
    '&:active': {
      backgroundColor: monochromeLight,
    },
    '&$selected': {
      backgroundColor: monochromeLightest,
    },
  },
};

export { selectTheme, paperTheme, listTheme, menuTheme, dividerTheme, listItemTheme };
