import React, { FC, useMemo, ReactNode, useRef, useCallback, useState } from 'react';
import { FormControl, Select, MenuProps, FormHelperText, MenuItem, Divider, SelectProps } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';

import { InputLabel } from '../InputLabel';
import { InferPropTypes, NonNullFields, ValidationPropType } from '../../types';

interface DropdownData {
  text: string;
  value: React.ReactText;
}

export type DataArray = DropdownData[];
export type MultiDimensionalDataArray = DropdownData[][];
export type DataType = DataArray | MultiDimensionalDataArray;

const dropdownPropTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  menuProps: PropTypes.object,
  open: PropTypes.bool,
  renderValue: PropTypes.func,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  displayEmpty: PropTypes.bool,
  optional: PropTypes.bool,
  hintText: PropTypes.string,
  dataTestId: PropTypes.string,
  helperText: PropTypes.node,
  validationType: PropTypes.oneOf(ValidationPropType),
  data: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
    ),
    PropTypes.arrayOf(
      PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string,
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }),
      ),
    ),
  ]),
};

const defaultDropdownPropTypes = {
  data: [],
  value: '',
  defaultValue: '',
  menuProps: {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 0,
      horizontal: 'left',
    },
    getContentAnchorEl: null,
  },
};

export type DropdownProps = NonNullFields<InferPropTypes<typeof dropdownPropTypes, typeof defaultDropdownPropTypes>> &
  SelectProps;

const Dropdown: FC<DropdownProps> = ({
  className,
  label,
  id,
  optional,
  hintText,
  disabled,
  required,
  helperText,
  validationType,
  children,
  data,
  value,
  menuProps,
  open,
  renderValue,
  defaultValue,
  onChange,
  dataTestId,
  native,
  inputProps,
  onClose,
  onOpen,
  placeholder,
  displayEmpty = true,
  style,
  ...rest
}) => {
  const selectRef = useRef<HTMLDivElement>(null);
  const isGrouped: boolean = useMemo(() => (data as DataType).some((d: DataArray | DropdownData) => Array.isArray(d)), [
    data,
  ]);
  const [selectValue, setSelectValue] = useState(value || defaultValue);

  const formedData: JSX.Element[] | null = useMemo(() => {
    if (children) {
      return null;
    }
    if (!isGrouped) {
      return (data as DataArray).map((option, idx) => (
        <MenuItem key={idx} value={option.value}>
          {option.text}
        </MenuItem>
      ));
    }

    return (data as MultiDimensionalDataArray)
      .map((dataArray: DataArray, index: number) => {
        const MapItems = dataArray.map((item: DropdownData, idx: number) => (
          <MenuItem key={`${index}${idx}${item.text}`} value={item.value}>
            {item.text}
          </MenuItem>
        ));

        return index === 0
          ? [...MapItems]
          : [<Divider data-testid={dataTestId && `${dataTestId}-divider`} key={`${index}divider`} />, ...MapItems];
      })
      .flat(1);
  }, [isGrouped, data, children, dataTestId]);

  const onCloseAction = useCallback(
    (e) => {
      if (e.type === 'click') {
        selectRef.current?.classList.remove('Mui-focused');
      }
      onClose && onClose(e);
    },
    [selectRef, onClose],
  );

  const changeSelectValue = useCallback(
    (e, item) => {
      setSelectValue(item.props.value);
      onChange && onChange(e, item);
    },
    [onChange],
  );

  const handleRenderValue = useCallback(
    (optionValue: unknown): ReactNode => {
      if (!renderValue) {
        return <span>{placeholder}</span>;
      }
      return typeof renderValue === 'function' ? renderValue(optionValue) : renderValue;
    },
    [placeholder, renderValue],
  );

  return (
    <FormControl
      style={style}
      fullWidth
      required={required}
      className={className}
      disabled={disabled}
      data-testid={dataTestId}
    >
      {label && <InputLabel htmlFor={id} label={label} optional={optional} hintText={hintText} disabled={disabled} />}
      <Select
        id={id}
        defaultValue={defaultValue}
        renderValue={renderValue || (placeholder && selectValue === '') ? handleRenderValue : undefined}
        open={open}
        MenuProps={menuProps as Partial<MenuProps>}
        disableUnderline={true}
        value={selectValue}
        IconComponent={ExpandMoreIcon}
        className={'select-input ' + validationType}
        onChange={changeSelectValue}
        native={native}
        data-testid={dataTestId && `${dataTestId}-select`}
        displayEmpty={displayEmpty}
        onClose={onCloseAction}
        onOpen={onOpen}
        inputProps={
          inputProps || {
            'data-testid': `${dataTestId}-input`,
          }
        }
        ref={selectRef}
        {...rest}
      >
        {children || formedData}
      </Select>
      {validationType && ['error', 'warning'].includes(validationType) && helperText && (
        <FormHelperText data-testid={`${dataTestId}-helper-text`} className={`helper-${validationType}`}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

Dropdown.propTypes = dropdownPropTypes;
Dropdown.defaultProps = defaultDropdownPropTypes;

export default Dropdown;
