import { AppBarClassKey, ToolbarClassKey } from '@material-ui/core/';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { Breakpoints } from '@material-ui/core/styles/createBreakpoints';
import { white } from '../../abstracts/colours';

export const MuiAppBar = (breakpoints: Breakpoints): Partial<Record<AppBarClassKey, CSSProperties>> => ({
  root: {
    backgroundColor: white,
    marginTop: 0,
    height: 80,
    justifyContent: 'center',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.04)',
    [breakpoints.down('sm')]: {
      height: 64,
    },
    [breakpoints.down('xs')]: {
      height: 56,
    },
  },
  colorPrimary: {
    backgroundColor: white,
  },
});
export const MuiToolbar: Partial<Record<ToolbarClassKey, CSSProperties>> = {
  root: {
    paddingLeft: 24,
    paddingRight: 24,
    '& .logo': {
      height: '32px',
      '& svg': {
        height: '100%',
        width: 'auto',
      },
    },
    '& .title-divider': { height: 80, margin: '0px 24px' },
  },
};
