import React from 'react';
import { Accordion as MuiAccordion, AccordionProps } from '@material-ui/core';

const Accordion: React.FC<AccordionProps> = ({ children, ...props }) => (
  <MuiAccordion {...props} square>
    {children}
  </MuiAccordion>
);

export default Accordion;
