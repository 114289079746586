import { Theme, BreadcrumbsClassKey } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

export const breadcrumbsTheme = (theme: Theme): Partial<Record<BreadcrumbsClassKey, CSSProperties>> => ({
  root: {
    '&.gutter-sm': {
      marginBottom: '1.5rem',
    },
    '&.gutter-md': {
      marginBottom: '2.5rem',
    },
    '&.gutter-lg': {
      marginBottom: '3.5rem',
    },
  },
  ol: {
    color: theme.palette.primary.main,
  },
});

export default { breadcrumbsTheme };
