import { monochromeDark, monochromeLight, monochromeLightest, monochromeMid } from '../../abstracts/colours';
import { sourceSansPro } from '../../abstracts/constants';

export const MuiPickersCalendarHeader = {
  switchHeader: {
    borderRadius: 4,
    border: `1px solid ${monochromeLight}`,
    height: 36,
    margin: 16,
    overflow: 'hidden',
  },
  iconButton: {
    border: `1px solid ${monochromeLight}`,
    borderRadius: 0,
    height: 36,
    borderRightWidth: 0,
    '&:first-child': {
      borderLeftWidth: 0,
      borderRightWidth: 1,
    },
    '&:hover': {
      backgroundColor: monochromeLightest,
    },
    '&:active': {
      backgroundColor: monochromeLight,
    },
  },
  dayLabel: {
    color: monochromeDark,
    fontSize: 14,
    fontWeight: 600,
    fontFamily: sourceSansPro,
  },
};
export const MuiPopover = {
  paper: {
    border: `1px solid ${monochromeMid}`,
    borderRadius: 4,
    boxShadow: '0 4px 6px 0 rgba(0, 0, 0, 0.12)',
  },
};
export const MuiPickersDay = {
  current: {
    border: `1px solid ${monochromeDark}`,
    color: monochromeDark,
  },
  day: {
    '&:hover': {
      backgroundColor: monochromeLightest,
    },
    '&:active': {
      backgroundColor: monochromeLight,
    },
  },
  daySelected: {
    borderWidth: 0,
  },
};
