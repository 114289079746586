import React, { FC } from 'react';
import { LinearProgress as MuiLinearProgress, LinearProgressProps as MuiLinearProgressProps } from '@material-ui/core/';
export interface LinearProgressProps extends MuiLinearProgressProps {
  dataTestId?: string;
}

const LinearProgress: FC<LinearProgressProps> = ({ dataTestId, ...props }) => (
  <MuiLinearProgress data-testid={dataTestId} {...props} />
);

export default LinearProgress;
