import { ThemeOptions, createMuiTheme } from '@material-ui/core/styles';
import { Overrides } from '@material-ui/core/styles/overrides';
import { MuiPickersOverrides } from '@material-ui/pickers/typings/overrides';
import buttonTheme, { buttonDefaultProps, MuiIconButton } from './buttonTheme';
import {
  buttonBaseDefaultProps,
  formHelperText,
  formLabelTheme,
  inputAdornmentTheme,
  inputBaseTheme,
  inputLabelDefaultProps,
  inputLabelTheme,
  inputTheme,
  textFieldDefaultProps,
} from './inputTheme';
import { formControlTheme } from './formControlTheme';
import { radioTheme } from './radioTheme';
import { paperTheme, listTheme, selectTheme, menuTheme, dividerTheme, listItemTheme } from './dropdownTheme';
import { checkboxTheme } from './checkboxTheme';
import createBreakpoints, { BreakpointsOptions } from '@material-ui/core/styles/createBreakpoints';
import { getTypographyOptions, typoOverrideTheme } from './typographyTheme';
import { basePalette } from './basePalette';
import { accordionSummaryTheme, accordionTheme } from './accordionTheme';
import switchTheme from './switchTheme';
import fontfaces from '../../abstracts/fonts';
import { MuiCardContent, MuiCardActionArea, MuiCard, MuiCardActions, MuiCardHeader } from './cardTheme';
import { breadcrumbsTheme } from './breadcrumbsTheme';
import { MuiLinearProgress } from './linearProgressTheme';
import { MuiPickersCalendarHeader, MuiPickersDay, MuiPopover } from './dateInputTheme';
import { MuiAppBar, MuiToolbar } from './headerTheme';

interface OverridenThemeOptions extends Omit<ThemeOptions, 'overrides'> {
  overrides?: MuiPickersOverrides & Overrides;
}

const breakpointOptions: BreakpointsOptions = {
  values: {
    xs: 0,
    sm: 600,
    md: 1024,
    lg: 1600,
    xl: 1920,
  },
};

const baseThemeOptions: ThemeOptions = {
  palette: basePalette,
  breakpoints: breakpointOptions,
};

const baseTheme = createMuiTheme(baseThemeOptions);
const breakpoints = createBreakpoints(breakpointOptions);
export const themeOptionsNoFonts: OverridenThemeOptions = {
  palette: basePalette,
  breakpoints: breakpointOptions,
  props: {
    MuiButton: buttonDefaultProps,
    MuiTextField: textFieldDefaultProps,
    MuiInputLabel: inputLabelDefaultProps,
    MuiButtonBase: buttonBaseDefaultProps,
  },
  typography: getTypographyOptions(baseTheme),
  overrides: {
    MuiButton: buttonTheme(),
    MuiIconButton,
    MuiInputBase: inputBaseTheme,
    MuiInput: inputTheme,
    MuiFormHelperText: formHelperText,
    MuiInputLabel: inputLabelTheme,
    MuiFormLabel: formLabelTheme,
    MuiInputAdornment: inputAdornmentTheme,
    MuiFormControl: formControlTheme,
    MuiCheckbox: checkboxTheme,
    MuiSwitch: switchTheme(baseTheme),
    MuiFormControlLabel: {
      label: {
        marginBottom: 0,
        fontFamily: 'Source Sans Pro',
        fontWeight: 600,
        lineHeight: '20px',
        height: 20,
        '& > .form-control-hint-text': {
          position: 'relative',
          top: 0,
          left: 0,
          fontFamily: 'Source Sans Pro',
          fontWeight: 400,
        },
      },
      labelPlacementStart: {
        marginLeft: 0,
        '& > .MuiFormControlLabel-label': {
          marginRight: 16,
        },
      },
    },
    MuiGrid: {
      root: {
        padding: baseTheme.spacing(2),
      },
    },
    MuiAppBar: MuiAppBar(breakpoints),
    MuiToolbar,
    MuiPickersCalendarHeader,
    MuiPopover,
    MuiPickersDay,
    MuiCardContent,
    MuiCardActionArea,
    MuiCard,
    MuiCardActions,
    MuiCardHeader,
    MuiLinearProgress,
    MuiSelect: selectTheme,
    MuiPaper: paperTheme,
    MuiList: listTheme,
    MuiTypography: typoOverrideTheme,
    MuiMenu: menuTheme,
    MuiDivider: dividerTheme,
    MuiListItem: listItemTheme,
    MuiRadio: radioTheme,
    MuiAccordion: accordionTheme,
    MuiAccordionSummary: accordionSummaryTheme(baseTheme),
    MuiBreadcrumbs: breadcrumbsTheme(baseTheme),
  },
};
export const themeOptions: OverridenThemeOptions = {
  ...themeOptionsNoFonts,
  overrides: {
    ...themeOptionsNoFonts.overrides,
    MuiCssBaseline: {
      '@global': {
        '@font-face': fontfaces,
      },
    },
  },
};
export const mainTheme = createMuiTheme(themeOptions);
export const mainThemeNoFonts = createMuiTheme(themeOptionsNoFonts);

export default mainTheme;
