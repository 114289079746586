import React, { FC } from 'react';
import { Typography, Grid } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import MuiSwitch, { SwitchProps as SwitchDefaultProps } from '@material-ui/core/Switch';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Color } from '../../types';
export interface SwitchProps extends SwitchDefaultProps {
  /**
   * Label that is displayed on the left side of toggle switch
   */
  labelLeft?: string;
  /**
   * Label that is displayed on the right side of toggle switch
   */
  labelRight?: string;

  onChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  size?: 'small' | 'medium';
  color?: Color;
  checked?: boolean;
  checkedIcon?: React.ReactNode;
  disabled?: boolean;
  icon?: React.ReactNode;
  autoFocus?: boolean;
  defaultChecked?: boolean;
  disableRipple?: boolean;
  id?: string;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  inputRef?: React.Ref<any>;
  name?: string;
  readOnly?: boolean;
  required?: boolean;
  tabIndex?: number;
  type?: React.InputHTMLAttributes<HTMLInputElement>['type'];
  value?: unknown;
  dataTestId?: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    disabled: {
      opacity: 0.3,
    },
  }),
);

const Switch: FC<SwitchProps> = ({
  checked,
  onChange,
  name,
  labelLeft,
  labelRight,
  color,
  disabled,
  dataTestId,
  className,
  style,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <Grid
      data-testid={dataTestId}
      className={clsx(className, disabled && classes.disabled)}
      component="label"
      container
      alignItems="center"
      spacing={1}
      style={style}
    >
      {labelLeft && (
        <Typography className="semi-bold" variant="caption">
          {labelLeft}
        </Typography>
      )}
      <Grid item>
        <MuiSwitch
          color={color}
          onChange={onChange}
          checked={checked}
          name={name}
          disabled={disabled}
          className={className && className + '-switch'}
          {...rest}
        />
      </Grid>
      {labelLeft && (
        <Typography className="semi-bold" variant="caption">
          {labelRight}
        </Typography>
      )}
    </Grid>
  );
};

Switch.propTypes = {
  labelLeft: PropTypes.string,
  labelRight: PropTypes.string,
  onChange: PropTypes.func,
  size: PropTypes.oneOf(['small', 'medium']),
  color: PropTypes.oneOf(['primary', 'secondary', 'default']),
  checked: PropTypes.bool,
  checkedIcon: PropTypes.node,
  disabled: PropTypes.bool,
  icon: PropTypes.node,
  autoFocus: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  disableRipple: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  tabIndex: PropTypes.number,
  dataTestId: PropTypes.string,
};

Switch.defaultProps = {
  color: 'primary',
};

export default Switch;
