import React, { FC, useMemo, useState } from 'react';
import {
  TextField as MuiTextField,
  StandardTextFieldProps as MuiStandardTextFieldProps,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import { ErrorOutline, CheckCircleOutline, Visibility, VisibilityOff } from '@material-ui/icons';
import { InputLabel } from '../InputLabel';
import { ValidationType } from '../../types';
import { EndAdornmentProps } from './types';
import clsx from 'clsx';

export interface TextFieldProps extends Omit<MuiStandardTextFieldProps, 'label'> {
  htmlFor?: string;
  label?: string;
  hintText?: string;
  validationType?: ValidationType;
  errorWasSet?: boolean;
  optional?: boolean;
  dataTestId?: string;
}

const TextField: FC<TextFieldProps> = ({
  InputProps,
  errorWasSet,
  type,
  validationType,
  helperText,
  disabled,
  required,
  dataTestId,
  label,
  hintText,
  optional,
  htmlFor,
  className,
  inputProps,
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const validationClassName: string = useMemo(() => {
    if (validationType) {
      return `helper-${validationType}`;
    }
    if (errorWasSet) {
      return 'helper-success';
    }
    return '';
  }, [validationType, errorWasSet]);

  const inputType = useMemo(() => {
    if (type !== 'password') {
      return type;
    }
    return showPassword ? 'text' : 'password';
  }, [showPassword, type]);

  return (
    <MuiTextField
      disabled={disabled}
      label={
        label && (
          <InputLabel
            label={label}
            hintText={hintText}
            htmlFor={htmlFor}
            required={required}
            optional={optional}
            className={className && className + 'label'}
          />
        )
      }
      type={inputType}
      InputProps={{
        disableUnderline: true,
        endAdornment: (
          <EndAdornment
            onClick={(): void => setShowPassword((show) => !show)}
            disabled={disabled}
            validationType={validationType as ValidationType}
            dataTestId={dataTestId}
            errorWasSet={errorWasSet}
            showPassword={showPassword}
            password={type === 'password'}
          />
        ),
        ...InputProps,
        className: clsx(validationClassName, className && className + '-input', InputProps?.className),
      }}
      helperText={helperText}
      FormHelperTextProps={{ className: `helper-${validationType}` }}
      inputProps={inputProps || { 'data-testid': dataTestId && `${dataTestId}-input` }}
      fullWidth
      className={className}
      {...rest}
    />
  );
};

TextField.defaultProps = {
  rows: 3,
};

const EndAdornment: FC<EndAdornmentProps> = ({
  disabled,
  password,
  validationType,
  errorWasSet,
  showPassword,
  onClick,
  dataTestId,
}) => {
  const PasswordAdornment: FC = () => {
    if (password) {
      return (
        <InputAdornment
          data-testid={dataTestId && `${dataTestId}-adornment`}
          className="input-adornment"
          position="end"
        >
          <IconButton
            data-testid={dataTestId && `${dataTestId}-adornment-button`}
            disabled={disabled}
            disableRipple
            onClick={onClick}
          >
            {!showPassword && <Visibility className="password-visibility" />}
            {showPassword && <VisibilityOff className="password-visibility" />}
          </IconButton>
        </InputAdornment>
      );
    }
    return null;
  };
  if (validationType) {
    return (
      <>
        <ErrorOutline data-testid={dataTestId && `${dataTestId}-adornment`} className={`helper-${validationType}`} />
        <PasswordAdornment />
      </>
    );
  }
  if (errorWasSet) {
    return (
      <>
        <CheckCircleOutline data-testid={dataTestId && `${dataTestId}-adornment`} className="helper-success" />
        <PasswordAdornment />
      </>
    );
  }
  return <PasswordAdornment />;
};

export default TextField;
